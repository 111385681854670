import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavBar.css';
import LanguageSwitcher from './../languageSwitcher/LanguageSwitcher';
import Logo from '../../assets/images/navbar/logo.png';

import { useTranslation } from 'react-i18next';

function NavBar() {
    const { t } = useTranslation();
    const location = useLocation();
    var path = location.pathname.slice(1);
    if (path === '') {
        path = 'home';
    }
    return (
        <div className={`navbar-container ${path}`}>
            <nav className="navbar">
                <div className="navbar-brand" height="90%">
                    <Link to="/" className="navbar-link">
                        <img src={Logo} className="navbar-img" alt="Yeterly Logo" />
                    </Link>
                </div>
                <div className="navbar-menu">
                    <Link to="/services" className="navbar-link">
                        <div className="navbar-link-text">{t('navbar.services')}</div>
                    </Link>
                    <Link to="/cases" className="navbar-link">
                        <div className="navbar-link-text">{t('navbar.cases')}</div>
                    </Link>
                    <Link to="/contact" className="navbar-link">
                        <div className="navbar-link-text">{t('navbar.contact')}</div>
                    </Link>
                </div>
                <div className="language-switcher-container">
                    <LanguageSwitcher />
                </div>
            </nav>
        </div>
    );
}

export default NavBar;