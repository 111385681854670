import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ServicesPage.css';
import ServicesTitle from './services-title/ServicesTitle';
import ServicesGrid from './services-grid/ServicesGrid';
import QuestioningFigure from '../../assets/images/services/questioning.png';

function ServicesPage() {
    const { t } = useTranslation();
    return (
        <div className="ServicesPage">
            <ServicesTitle />
            <ServicesGrid />
            <div className='services-more-info-container'>
                <h1>{t('services.moreInfo.title')}</h1>
                <p>{t('services.moreInfo.text')}</p>
                <Link to='/contact' className='services-more-info-button'>
                    {t('services.moreInfo.action')}
                </Link>
                <div className='services-more-image-container'>
                    <img src={QuestioningFigure} alt='Questioning' />
                </div>
            </div>
        </div>
    );
}

export default ServicesPage;